import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem } from "../styles/utils";

export const CCardYearsNavUL = css`
  display: flex;
  list-style-type: none;
`;

export const CCardYearsNavLI = styled.li`
  margin: 0 10px;
  font-size: ${rem(32)};
  cursor: pointer;

  ${({ dataActive }) => (dataActive && "text-decoration:underline;") || ""};
`;