import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import ChallengeWinners from "app/templates/challenge-winners";

const ChallengeWinnersPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "challenge-winners" }) {
        ...ChallengeWinnersPage
      }
    }
  `);

  return <ChallengeWinners {..._get(data, "contentJson")} />;
};

export default ChallengeWinnersPage;
