import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

const GET_CHALLENGE_WINNERS_QUERY = graphql`
  query {
    allChallengeWinnersJson {
      nodes {
        ...ChallengeWinner
      }
    }
  }
`;

export const useChallengeWinnersData = () => {
  const data = useStaticQuery(GET_CHALLENGE_WINNERS_QUERY);

  return _get(data, "allChallengeWinnersJson.nodes") || [];
};

export default useChallengeWinnersData;
