import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import ChallengeWinnersCardList from "app/components/challenge-winners-card-list";
import HeroBar, { heroBarField } from "app/components/hero-bar";
import Layout from "app/components/layout";
import SEOMeta, { seoField } from "app/components/seo";
import MarkdownBlock from "app/components/markdown-block";

import * as S from "app/components/page.styles";

const ChallengeWinners = ({ title, intro, ctaBlock, ...props }) => (
  <Layout>
    <SEOMeta title={_get(props, "title")} {..._get(props, "seo")} />
    <S.PageBody as="article">
      <S.PageHeader as="header">
        <S.PageCat as="h1">{title}</S.PageCat>
        <S.PageTitle as="div">
          <MarkdownBlock
            content={intro}
            components={{ div: React.Fragment }}
          />
        </S.PageTitle>
      </S.PageHeader>
      <ChallengeWinnersCardList shadowColor={"#bde0e7"} />
      <HeroBar {...ctaBlock} />
    </S.PageBody>
  </Layout>
);

export default ChallengeWinners;

export const challengeWinnersFragment = graphql`
  fragment ChallengeWinnersPage on ContentJson {
    ...SEO
    title
    intro
    ...HeroBar
  }
`;

export const challengeWinnersFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "markdown",
  },
  heroBarField,
];
