import React, { useState, useEffect } from "react";

import { useChallengeWinnersData } from "app/hooks/use-challenge-winners-data";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/challenges-card-list.styles";
import * as winnerStyles from "app/components/challenge-winners-card-list.styles";

import CCard from "app/components/challenge-card";

const S = {
  ...stylesShared,
  ...styles,
  ...winnerStyles
};

const ChallengeWinnersList = ({ challenges, excluded, title, shadowColor }) => {
  const [currentYear, setCurrentYear] = useState(2021);
  const [allowFlip, setAllowFlip] = useState(true);

  useEffect(() => {
    setAllowFlip(false);

    setTimeout(() => {
      setAllowFlip(true);
    }, 0);
  }, [currentYear]);

  // create list of years.
  const years = [];
  challenges.forEach(challenge => {
    if ( !years.includes(parseInt(challenge.year)) ) {
      years.push(parseInt(challenge.year));
    }
  });

  // sort the years by recent year first.
  years.sort((a, b) => b - a);

  // set the first year to be active.
  useEffect(() => {
    setCurrentYear(years[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container css={S.ccardContainer}>

      {title && <S.CCardListTitle>{title}</S.CCardListTitle>}

      <ul css={S.CCardYearsNavUL}>
        {years.map(year =>
        (<S.CCardYearsNavLI dataActive={ year === currentYear } onClick={(e) => {
          setCurrentYear(year)
        }}>{year}</S.CCardYearsNavLI>)
      )}
      </ul>
      <S.CCardList>
        {challenges
          .filter(({ slug }) => excluded.indexOf(slug) === -1)
          .filter(({ year }) => parseInt(currentYear) === parseInt(year))
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((challenge, idx) => (
            <CCard
              key={idx}
              {...challenge}
              shadowColor={shadowColor}
              css={S.CCardLI}
              baseUri="/challenge-winners"
              viewDetailsLabel="View Winners"
              allowFlip={allowFlip}
            />
          ))}
      </S.CCardList>
    </S.Container>
  );
};

ChallengeWinnersList.defaultProps = {
  challenges: [],
  excluded: [],
};

const ChallengeWinnersListWithData = (data) => {
  return (
    <ChallengeWinnersList {...data} challenges={useChallengeWinnersData()} />
  );
};

export default ChallengeWinnersListWithData;
